<template>
  <div class="container">
    <h3>{{ metadata.formTitle }}</h3>
    <form class="row g-3" @submit.prevent="onSubmit">
      <v-tabs center-active v-model="tab" grow>
        <v-tab href="#tab-1"> {{ section }} </v-tab>
        <v-tab href="#tab-2"> Documents </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <template v-for="field in metadata.fields">
            <text-item
              :label="field.label"
              :name="field.name"
              :classes="field.classes"
              :title="field.title"
              :placeholder="field.placeholder"
              :disabled="getBooleanFromCondition(field.disabled, false)"
              :required="getBooleanFromCondition(field.required, false)"
              @change="gatherFieldValue"
              v-if="field.type === 'String' || field.type === 'Text'"
              :key="field.name"
              :condition="getBooleanFromCondition(field.showCondition, true)"
            >
            </text-item>
            <number-item
              :label="field.label"
              :name="field.name"
              :classes="field.classes"
              :title="field.title"
              :placeholder="field.placeholder"
              :disabled="getBooleanFromCondition(field.disabled, false)"
              :required="getBooleanFromCondition(field.required, false)"
              @change="gatherFieldValue"
              v-if="field.type === 'Number'"
              :key="field.name"
              :condition="getBooleanFromCondition(field.showCondition, true)"
            >
            </number-item>
            <date-item
              :label="field.label"
              :name="field.name"
              :classes="field.classes"
              :title="field.title"
              :placeholder="field.placeholder"
              :disabled="getBooleanFromCondition(field.disabled, false)"
              :required="getBooleanFromCondition(field.required, false)"
              @change="gatherFieldValue"
              v-model="valueObj[field.name]"
              v-if="field.type === 'Date'"
              :key="field.name"
              :condition="getBooleanFromCondition(field.showCondition, true)"
            >
            </date-item>
            <combo-item
              :label="field.label"
              :name="field.name"
              :classes="field.classes"
              :keyValues="field.values"
              :title="field.title"
              :placeholder="field.placeholder"
              :disabled="getBooleanFromCondition(field.disabled, false)"
              :required="getBooleanFromCondition(field.required, false)"
              v-model="valueObj[field.name]"
              @change="gatherFieldValue"
              :key="field.name"
              v-if="field.type === 'Select'"
              :condition="getBooleanFromCondition(field.showCondition, true)"
            >
            </combo-item>
            <radio-item
              :label="field.label"
              :name="field.name"
              :classes="field.classes"
              :keyValues="field.values"
              :title="field.title"
              :placeholder="field.placeholder"
              :disabled="getBooleanFromCondition(field.disabled, false)"
              :required="getBooleanFromCondition(field.required, false)"
              v-model="valueObj[field.name]"
              @change="gatherFieldValue"
              :key="field.name"
              v-if="field.type === 'Radio'"
              :condition="getBooleanFromCondition(field.showCondition, true)"
            >
            </radio-item>
          </template>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-file-input
            v-for="doc in metadata.documents"
            :key="doc.name"
            :label="doc.desc"
          ></v-file-input>
        </v-tab-item>
      </v-tabs-items>
      <div class="row justify-content-end my-4">
        <div class="col-2 pa-2 justify-content-end d-flex">
          <button type="button" class="btn btn-danger" @click="reset">
            Clear
          </button>
        </div>
        <div class="col-2 pa-2">
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import TextItem from "./items/TextItem.vue";
import NumberItem from "./items/NumberItem.vue";
import DateItem from "./items/DateItem.vue";
import ComboItem from "./items/ComboItem.vue";
import RadioItem from "./items/RadioItem.vue";

export default {
  name: "DynaUI",
  components: {
    TextItem,
    NumberItem,
    DateItem,
    ComboItem,
    RadioItem,
  },
  props: ["metadata", "section"],
  data() {
    return {
      defaultValues: {},
      valueObj: {},
      tab: null,
      valueAlias: "this.valueObj",
    };
  },
  mounted() {
    this.metadata.fields.forEach(
      (field) => (this.defaultValues[field.name] = field.defaultValue || null)
    );
    this.reset();
  },
  methods: {
    reset() {
      this.valueObj = { ...this.defaultValues };
    },
    onSubmit(event) {
      console.log(JSON.stringify(this.valueObj));
      //this.reset();
    },
    gatherFieldValue(event) {
      this.valueObj = { ...this.valueObj, ...event };
    },
    getBooleanFromCondition(cond, def) {
      if (typeof(cond) === "string") {
        cond = cond.replace(new RegExp("@i.", "g"), this.valueAlias + ".");
        let retVal = eval(cond);
        return (typeof(retVal) != "boolean") ? true : retVal;
      }
      return (typeof(cond) === 'boolean') ? cond : def;
    },
  },
};
</script>
