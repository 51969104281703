<template>
  <div>
    <div class="overflow-hidden">
      <div class="container-fluid">
        <div class="row">
          <Sidebar />
          <div class="col-md-10 offset-md-2 pr-10 overflow-hidden">
            <div class="row">
              <div class="col-10">
                <AddEditConfig :ruleDetails="ruleDetails" :payload="payload" title="ADD CONFIG" type="add" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar.vue";
import AddEditConfig from "../components/AddEditConfig.vue";
export default {
  name: "AddConfig",
  components: {
    Sidebar,
    AddEditConfig,
  },
  data() {
    return {
      ruleDetails: {},
      payload: {},
    };
  },
  methods: {},
  created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>

<style scoped>
</style>