export const CommonFunctions = {
    convertTime(dateISO, withTime = true) {
        let date = new Date(dateISO);
        let year = date.getFullYear();
        let month = parseInt(date.getMonth() + 1).toString().padStart(2, "0");
        let dt = date.getDate().toString().padStart(2, "0");
        if (withTime) {
            let hr = date.getHours().toString().padStart(2, "0");
            let min = date.getMinutes().toString().padStart(2, "0");
            let sec = date.getSeconds().toString().padStart(2, "0");
            return month + "-" + dt + "-" + year + " " + hr + ":" + min + ":" + sec;
        } else {
            return month + "-" + dt + "-" + year;
        }
    },
    getDateString() {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        let hr = date.getHours().toString().padStart(2, "0");
        let min = date.getMinutes().toString().padStart(2, "0");
        let sec = date.getSeconds().toString().padStart(2, "0");
        return `${year}${month}${day}${hr}${min}${sec}`
    }
}