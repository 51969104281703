<template>
  <div class="form-group">
    <v-text-field
      :name="name"
      :label="label"
      :value="value"
      v-model="textValue"
      :class="classes"
      :required="required"
      :placeholder="placeholder"
      :title="title"
      :disabled="disabled"      
      @input="emitItemValue"    
      v-show="condition"        
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "TextItem",
  props: {
    name: String,
    label: String,
    value: String,    
    classes : String,
    required : Boolean,
    placeholder : String,
    title : String,
    disabled : Boolean,
    condition : Boolean,
  },
  methods: {
   
  },
  data(){
    return{
      textValue:this.value
    }
  },
  methods:{
    emitItemValue(event) {
      this.$emit("change", { [this.name]: event});
    },
  }
};
</script>