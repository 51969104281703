<template>
  <v-card class="elevation-1 m-3">
    <div class="flex-column">
      <div class="col-md-10 offset-md-2 pr-10 mt-5 p-4">
        <div class="row">
          <div class="col-4">
            <strong>{{ log.docName }}</strong>
          </div>
        </div>
        <div class="overflow-x-scroll">
          <div class="container-fluid">
            <div class="row">
              <Sidebar />
            </div>
          </div>
        </div>
        <br />
        <div v-if="this.log.entityData" class="my-4">
          <span class="pb-1 h6">Entity Type:</span>
          <span class="text-caption">
            {{ this.log.entityData.entityType }}
          </span>
        </div>
        <div v-if="this.log.entityData" class="my-4">
          <span class="pb-1 h6">Entity Id:</span>
          <span class="text-caption">
            {{ this.log.entityData.entityId }}
          </span>
        </div>
        <div v-if="this.log.isError" class="my-4">
          <span class="pb-1 h5">Response Error:</span>
          <div class="text-caption">
            {{ this.log.error.message }}
          </div>
        </div>
        <hr v-if="this.log.isError" />
        <label for="payloadInput" class="pb-1 h5">Request Payload:</label>
        <custom-vue-json-editor
          v-model="payloadInput"
          :mode="'view'"
          :modes="['view']"
          :expandedOnStart="true"
        ></custom-vue-json-editor>
      </div>
    </div>
    <div>
      <v-snackbar
        class="m-5"
        v-model="snackbar"
        :timeout="timeout"
        :color="snackcolor"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import CustomVueJsonEditor from '../components/CustomVueJsonEditor.vue';

export default {
  name: 'ViewRule',
  components: {
    Sidebar,
    CustomVueJsonEditor,
  },
  props: {
    id: String,
  },
  data() {
    return {
      log: {},
      payloadInput: {},
      timeout: 3000,
      snackbar: false,
      snackcolor: 'red',
      message: '',
    };
  },

  async created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: 'Login',
      });
    }
    const res = this.$http
      .get(`/api/DocgenMonitors/${this.id}`)
      .then((res) => {
        this.log = res.data;
        this.payloadInput = {
          requestData: this.log.requestData,
        };
      })
      .catch((error) => {
        console.error(error.response.data.error.message);
        this.snackbar = true;
        this.snackcolor = 'red';
        this.message = error.response.data.error.message;
      });
  },
};
</script>

<style scoped>
#ruleName,
#ruleDesc {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.v-textarea {
  font-size: 0.75rem;
  line-height: 0.8rem;
}

.theme--light.v-btn {
  color: white;
}
</style>
