<template>
  <div class="app-div overflow-hidden">
    <v-app>
      <Header />
      <router-view class="app-div mt-15"></router-view>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: $primary-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-font-color;
  background-color: #E2EAEF;
}
.app-div {
  min-height: 90vh;
}
#message_div {
  bottom: 10%;
  position: absolute;
  left: 20%;
  width: 40%;
}
</style>