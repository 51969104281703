<template>
  <div class="overflow-hidden">
    <div class="container-fluid">
      <div class="row">
        <Sidebar />
        <div
          class="col-md-10 offset-md-2 pr-10"
          :validationErrors="validationErrors"
        >
          <div class="row">
            <div :class="[editApproveColClass]">
              <AddEditRule
                :ruleDetails="ruleDetails"
                :currentRule="currentRule"
                :payload="payload"
                :title="title"
                :type="editOrApprove"
                :validationErrors="validationErrors"
              />
            </div>
            <div class="col-6 ms-auto mt-5" v-if="editOrApprove === 'edit'">
              <div class="d-flex justify-content-center p-1 mb-2">
                <strong>Version History</strong>
              </div>
              <div
                class="justify-content-end overflow-hidden"
                v-if="ruleHistoryDetails.length"
              >
                <v-card-text class="py-0 timeline">
                  <v-timeline align-top dense>
                    <v-timeline-item
                      v-for="(history, idx) in ruleHistoryDetails"
                      :key="history._version"
                      :color="getAlternateColor(idx)"
                      fill-dot
                      dark
                    >
                      <template v-slot:icon>
                        <span class="text-white"
                          ><b
                            >v{{ history.custVersion }}.{{
                              history.minorVersion || 0
                            }}</b
                          ></span
                        >
                      </template>
                      <v-card :color="getAlternateColor(idx)" dark>
                        <v-expansion-panels>
                          <v-expansion-panel>
                            <v-expansion-panel-header
                              :color="getAlternateColor(idx)"
                            >
                              <v-card-title>
                                {{ history.remarks }} :
                                {{ history.level }} Level
                              </v-card-title>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content
                              class="white text--primary"
                            >
                              <!-- <v-card-text class="white text--primary"> -->
                              <div class="row">
                                <div class="col-9">
                                  Created By&nbsp;&nbsp;&nbsp;&nbsp; :<strong>{{
                                    history._modMaker
                                  }}</strong
                                  ><br />
                                  Created On&nbsp;&nbsp;&nbsp; :<strong>{{
                                    convertTime(history._modMakerOn)
                                  }}</strong
                                  ><br />
                                  Approved By&nbsp; :<strong>{{
                                    history._modifiedBy
                                  }}</strong
                                  ><br />
                                  Approved On :<strong>{{
                                    convertTime(history._modifiedOn)
                                  }}</strong>
                                </div>

                                <div
                                  class="col-3 d-flex justify-content-end btn-panel"
                                >
                                  <div>
                                    <v-btn
                                      :color="getAlternateColor(idx + 1)"
                                      class="mx-0"
                                      title="Download this version"
                                      @click="downloadVersion(history)"
                                    >
                                      Download
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-dialog
                                      v-model="dialog"
                                      width="500"
                                      v-if="showRestore(history)"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          style="min-width: 108px"
                                          :color="getAlternateColor(idx)"
                                          class="mx-0 mt-3"
                                          title="Restore this version"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          Restore
                                        </v-btn>
                                      </template>
                                      <v-card>
                                        <v-card-title
                                          :class="[
                                            getAlternateColor(idx),
                                            'text-white',
                                          ]"
                                        >
                                          Restore Rule Version : v{{
                                            history.custVersion
                                          }}.{{ history.minorVersion || 0 }}
                                        </v-card-title>
                                        <v-card-text class="mt-5">
                                          Are you sure you want to restore this
                                          version of the rule?
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            color="primary"
                                            text
                                            @click="dialog = false"
                                          >
                                            Cancel
                                          </v-btn>
                                          <v-btn
                                            color="primary"
                                            text
                                            @click="restoreVersion(history)"
                                          >
                                            OK
                                          </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-dialog>
                                  </div>
                                </div>
                              </div>
                              <!-- </v-card-text> -->
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <v-snackbar
          class="m-5"
          v-model="snackbar"
          :timeout="timeout"
          :color="snackcolor"
        >
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import AddEditRule from '../components/AddEditRule.vue';
//import CompareRule from "../components/CompareRule.vue";
import { RuleFunctions } from '../common/rule-functions.js';
import { CommonFunctions } from '../common/common-functions.js';
export default {
  name: 'EditRule',
  delimiters: ['${', '}'],
  components: {
    Sidebar,
    AddEditRule,
    //CompareRule,
  },
  props: {
    id: String,
    type: String,
    name: String,
  },
  data() {
    return {
      ruleDetails: {},
      approvalCurrentRule: {},
      ruleHistoryDetails: [],
      filelist: [],
      payload: null,
      remarks: '',
      message: '',
      title: this.type === 'approve' ? 'APPROVE/REJECT RULE' : 'EDIT RULE',
      editOrApprove: this.type === 'approve' ? 'approve' : 'edit',
      editApproveColClass: this.type === 'approve' ? '' : 'col-6',
      dialog: false,
      timeout: 3000,
      snackbar: false,
      snackcolor: 'red',
      validationErrors: [],
    };
  },
  methods: {
    async getData(model) {
      try {
        const response = await this.$http.get(
          `/api/${model}?filter[where][name]=${this.name}`
        );
        let ruleData = response.data[0];
        if (this.id != ruleData.id) {
          this.snackcolor = 'red';
          this.snackbar = true;
          this.message =
            'Mismatch in Rule Details. Refresh Dashboard for the latest';
          setTimeout((e) => {
            this.$router.push({
              name: 'RulesDashboard',
              params: {
                type: 'all',
              },
            });
          }, 2000);
        } else {
          this.ruleDetails = response.data[0];
          this.payload = {
            ...this.ruleDetails,
          };
          delete this.payload.weight;
          delete this.payload._createdOn;
          delete this.payload._createdBy;
          delete this.payload._modifiedOn;
          delete this.payload._modifiedBy;
        }

        //delete this.payload._version;
      } catch (error) {
        console.log(error);
        //console.error(error.response.data.error.message);
        //this.snackcolor = "red";
        //this.snackbar = true;
        //this.message = error.response.data.error.message;
      }
    },
    async getHistoryData() {
      try {
        let filterCond = {
          where: {
            and: [
              {
                name: this.ruleDetails.name,
              },
              {
                or: [
                  {
                    '_autoScope.tenantId': '/default',
                  },
                  {
                    '_autoScope.tenantId': sessionStorage.tenant_id,
                  },
                ],
              },
            ],
          },
          order: '_modifiedOn DESC',
        };
        //let api = `/api/DecisionTables/history?filter=` + JSON.stringify(filterCond);
        let api = `/api/DecisionTables/${this.ruleDetails.name}/fetchHistory`;
        //let api = `/api/DecisionTables/history?filter[where][_modelId]=${this.id}&filter[order][0]=_modifiedOn%20DESC`;
        const response = await this.$http.get(api);
        this.ruleHistoryDetails = response.data;
      } catch (error) {
        console.log(error);
        console.error(error.response.data.error.message);
        this.snackcolor = 'red';
        this.snackbar = true;
        this.message = error.response.data.error.message;
      }
    },
    restoreVersion(oldrule) {
      this.dialog = false;
      //if (confirm("Do you want to restore this version of the rule?")) {
      let api = '/api/DecisionTables/restorePreviousVersion';
      let restorePayload = {};
      restorePayload.versionId = oldrule.id;
      restorePayload.modelName = 'DecisionTable';
      this.$http
        .post(api, restorePayload)
        .then((response) => {
          this.snackcolor = 'green';
          this.snackbar = true;
          this.message = 'Rule Restored Successfully';
          setTimeout((e) => {
            this.$router.push({
              name: 'RulesDashboard',
              params: {
                type: 'all',
              },
            });
          }, 2000);
        })
        .catch((error) => {
          //console.error("There was an error during restoring rule!", error);
          console.error(error.response.data.error.message);
          this.snackbar = true;
          this.snackcolor = 'red';
          this.message = error.response.data.error.message;
        });
      //}
    },
    downloadVersion(oldRule) {
      RuleFunctions.downloadRule(
        oldRule.id,
        'DecisionTableHistory',
        oldRule._version
      );
    },

    getAlternateColor(idx) {
      return idx % 2 === 0 ? 'primary' : 'secondary';
      //return idx % 2 === 0 ? "#1c316d" : "#d4503f";
    },
    convertTime(dateTime) {
      return CommonFunctions.convertTime(dateTime);
    },
    showRestore(rec) {
      let recTenant =
        rec._autoScope && rec._autoScope.tenantId
          ? rec._autoScope.tenantId
          : '';
      let getCurrentTenant = sessionStorage.getItem('tenant_id');
      if (recTenant != getCurrentTenant) return false;
      return true;
    },
    async getInitialDetails() {
      let model = 'DecisionTables';
      if (this.type === 'approve') {
        model = 'DecisionTableMods';
      }
      await this.getData(model);
      if (this.type === 'approve') {
        let url = `/api/DecisionTables?filter[where][name]=${this.name}`;
        const response = await this.$http.get(`${url}`);
        this.approvalCurrentRule = response.data[0];
        url = `/api/DecisionTableMods/validateRule`;
        let payload = this.ruleDetails;
        await this.$http
          .post(url, payload)
          .then((response) => {
            let validationErrors = response.data || [];
            validationErrors.forEach((err, idx) => {
              this.validationErrors.push({ index: idx + 1, error: err });
            });
          })
          .catch((error) => {
            //console.error("There was an error during restoring rule!", error);
            console.error(error.response.data.error.message);
            this.snackbar = true;
            this.snackcolor = 'red';
            this.message = error.response.data.error.message;
          });
      }
      if (this.type !== 'approve') {
        await this.getHistoryData();
      }
    },
  },
  async created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: 'Login',
      });
    }
    await this.getInitialDetails();
  },
  computed: {
    currentRule() {
      return this.approvalCurrentRule;
    },
  },
  // watch: {
  //   async id(val, old) {
  //     if (val && val !== old) await this.getInitialDetails();
  //   },
  // },
};
</script>

<style lang="scss" scoped>
#message_div {
  bottom: 10%;
  position: absolute;
  left: 18%;
  width: 40%;
}

.theme--light.v-timeline:before {
  _background: linear-gradient(180deg, black 0% 50%, gray 50% 100%) !important;
  _left: 3rem;
  _right: auto;
}

.v-card__title {
  word-break: unset;
  line-height: unset;
  font-size: 1rem;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 4px 12px;
}

.v-expansion-panel-header {
  min-height: unset;
  padding: unset;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}

.v-application--is-ltr
  .v-timeline--dense
  .v-timeline-item
  .v-timeline-item__body
  .v-card:after,
.v-application--is-ltr
  .v-timeline--dense
  .v-timeline-item
  .v-timeline-item__body
  > .v-card:before {
  top: 5px;
}
</style>
