<template>
  <!-- <v-card> -->
  <v-card class="elevation-1 m-3">
    <div class="p-3">
      <div>
        <b>{{ title }}</b>
      </div>

      <!-- <v-card class="p-4 m-4"> -->
      <div class="overflow-x-hidden g-light">
        <div class="row ml-2">
          <div class="d-flex justify-content-end" v-if="type === 'add'">
            <v-btn
              color="primary"
              depressed
              class="btn btn-primary ms-auto"
              @click="uploadRule"
            >
              {{ addOrUpdate }} Config
            </v-btn>
          </div>
          <div
            class="gap-3 d-flex justify-content-end"
            v-if="type === 'approve'"
          >
            <!-- <v-btn class="btn btn-primary" color="primary" @click="downloadRule(ruleDetails.id, 'SpreadingConfigMod' ,ruleDetails._version)">
              Download
            </v-btn> -->
            <v-btn
              class="btn btn-success"
              color="success"
              @click="approveRule(ruleDetails)"
            >
              Approve
            </v-btn>
            <v-btn
              depressed
              class="btn btn-danger"
              @click="rejectRule(ruleDetails)"
            >
              Reject
            </v-btn>
            <!-- <CompareRule :rule="ruleDetails" type="approve" /> -->
          </div>
        </div>
        <div class="py-5" v-if="type !== 'add'">
          <div class="d-flex justify-content-between py-2">
            <div>
              <b>{{ ruleDetails.name }}</b
              >&nbsp;&nbsp;&nbsp;<span v-if="type !== 'edit'">
                <v-avatar class="" :color="getAlternateColor()" size="50">
                  <span class="text-white"
                    ><b
                      >v{{ ruleDetails.custVersion }}.{{
                        ruleDetails.minorVersion || 0
                      }}</b
                    ></span
                  >
                </v-avatar>
              </span>
            </div>
            <div class="d-flex justify-content-end" v-if="type === 'edit'">
              <v-btn class="btn btn-primary ms-auto p-1" @click="uploadRule">
                {{ addOrUpdate }}
              </v-btn>
              <!-- <v-btn class="btn btn-primary p-1 ml-10p" @click="downloadRule(ruleDetails.id, 'SpreadingConfig' ,ruleDetails._version)" v-if="type == 'edit'">
                Download
              </v-btn> -->
            </div>
          </div>
          <div>
            <i v-if="type === 'approve'">{{ ruleDetails.remarks }}</i>
            <div class="row" v-if="type === 'edit'">
              <div class="col-2">
                <v-avatar class="" :color="getAlternateColor()" size="50">
                  <span class="text-white"
                    ><b
                      >v{{ ruleDetails.custVersion }}.{{
                        ruleDetails.minorVersion || 0
                      }}</b
                    ></span
                  >
                </v-avatar>
              </div>
              <div class="col-10">
                <v-card dark class="">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header :color="getAlternateColor()">
                        <v-card-title>
                          {{ ruleDetails.remarks }}
                        </v-card-title>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="white text--primary">
                        <!-- <v-card-text class="white text--primary"> -->
                        <div class="row">
                          <div class="col-12" style="font-size: 0.875rem">
                            Created By&nbsp;&nbsp;&nbsp;&nbsp; :
                            <strong>{{ ruleDetails._modMaker }}</strong
                            ><br />
                            Created On&nbsp;&nbsp;&nbsp; :
                            <strong>{{
                              convertTime(ruleDetails._modMakerOn)
                            }}</strong
                            ><br />
                            Approved By&nbsp; :
                            <strong>{{ ruleDetails._modifiedBy }}</strong
                            ><br />
                            Approved On :
                            <strong>{{
                              convertTime(ruleDetails._modifiedOn)
                            }}</strong>
                          </div>
                        </div>
                        <!-- </v-card-text> -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-auto ml-4">
          <br />
          <div v-if="type === 'add'">
            <label for="ruleName" class="pb-1 mandatory">Config Name</label>
            <v-text-field
              v-model="ruleName"
              :rules="rules"
              outlined
              dense
              solo
              clearable
            ></v-text-field>
          </div>
          <div>
            <label for="remarks" class="pb-1 mandatory">{{
              remarksTitle
            }}</label>
            <v-textarea
              v-if="type !== 'approve'"
              outlined
              dense
              solo
              clearable
              rows="3"
              :rules="rules"
              v-model="remarks"
            ></v-textarea>
          </div>
          <v-radio-group
            v-model="minorMajor"
            :disabled="type === 'add' || type === 'approve'"
          >
            <v-radio value="Minor" label="Bug Fix in Config"></v-radio>
            <v-radio value="Major" label="Config Update"></v-radio>
          </v-radio-group>
          <label for="configData" class="pb-1 mandatory">Configuration</label>
          <!-- <v-textarea :disabled="type == 'approve'" outlined dense solo clearable rows="12" :rules="rules" v-model="configData"></v-textarea> -->
          <custom-vue-json-editor
            v-if="type === 'add'"
            v-model="configData"
            :expandedOnStart="true"
            :mode="'code'"
          ></custom-vue-json-editor>
          <custom-vue-json-editor
            v-else-if="type === 'edit'"
            v-model="ruleDetails.configData"
            :expandedOnStart="true"
            :mode="'code'"
          ></custom-vue-json-editor>
          <custom-vue-json-editor
            v-else
            v-model="ruleDetails.configData"
            :expandedOnStart="true"
            :mode="'view'"
            :modes="['view']"
          ></custom-vue-json-editor>
        </div>
      </div>
      <!-- <div class="ml-4">
        <CompareRule :rule="ruleDetails" type="edit" :newdata="payload" v-if="type === 'edit'" />
      </div>
      <h6 class="ml-4 fw-bold pb-0" v-if="type == 'approve'">Rule to be Approved</h6>
      <div class="ml-4" v-if="type == 'approve'">
        <RuleTable :decisionRules="getDecisionDetails(ruleDetails)" called_from="new_rule" :currentRule="getCurrentDecisionDetails()" />
      </div>
      <h6 class="ml-4 fw-bold pt-4" v-if="type == 'approve'">Current Existing Rule </h6>
      <div class="ml-4 pb-16" v-if="type == 'approve'">
        <RuleTable :decisionRules="getCurrentDecisionDetails()" called_from="old_rule" />
      </div> -->
      <div>
        <v-snackbar
          class="m-5"
          v-model="snackbar"
          :timeout="timeout"
          :color="snackcolor"
        >
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <!-- </v-card> -->
    </div>
  </v-card>
  <!-- </v-card> -->
</template>

<script>
import { RuleFunctions } from '../common/rule-functions.js';
import { CommonFunctions } from '../common/common-functions.js';
import CustomVueJsonEditor from './CustomVueJsonEditor.vue';

export default {
  name: 'AddEditConfig',
  components: { CustomVueJsonEditor },
  props: {
    ruleDetails: {
      type: Object,
      default: () => ({}),
    },
    currentRule: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
    title: String,
    type: String,
    validationErrors: Array,
  },
  data() {
    return {
      filelist: [],
      remarks: '',
      rules: [
        (value) => !!value || 'Required.',
        (value) => (value && value.length >= 3) || 'Min 3 characters',
      ],
      message: '',
      ruleName: '',
      configData: {},
      minorMajor: '',
      addOrUpdate: this.type === 'add' ? 'Add' : 'Update',
      remarksTitle:
        this.type === 'add'
          ? 'Config Description'
          : this.type === 'approve'
          ? 'Remarks'
          : 'Change Description',
      uploadboxclass: this.type === 'add' ? '' : 'w-full',
      decisionRules: {},
      timeout: 3000,
      snackbar: false,
      snackcolor: 'red',
      validationErrorHeaders: [
        /*{
        text: "No.",
        hidden : true,
        value: "index",
        width:"18%",
        class:"text-white bg-dark"
      },*/
        {
          text: 'Error',
          value: 'error',
          class: 'text-white bg-dark',
        },
      ],
    };
  },
  watch: {
    ruleDetails: function (d) {
      this.minorMajor =
        this.type === 'add'
          ? 'Major'
          : this.type === 'approve'
          ? this.ruleDetails.minorMajor
          : 'Minor';
    },
  },
  methods: {
    convertTime(dateISO) {
      return CommonFunctions.convertTime(dateISO);
    },
    getAlternateColor(idx) {
      return 'secondary';
      //return idx % 2 === 0 ? "#1c316d" : "#d4503f";
    },
    async uploadRule(evt) {
      try {
        console.log('configData', this.configData);
        if ((Object.keys(this.configData).length === 0) && (Object.keys(this.ruleDetails.configData).length === 0) ) {
          this.snackbar = true;
          this.snackcolor = 'red';
          this.message = 'Please enter the Config Data';
          return;
        } else if (this.remarks.trim() === '') {
          this.snackcolor = 'red';
          this.snackbar = true;
          this.message = 'Provide ' + this.remarksTitle;
          //document.getElementById("remarks").focus();
          return;
        } else {
          this.payload.configData = this.configData;
          if (this.type != "add") {
            this.payload.configData = this.ruleDetails.configData;
          }
          if (this.ruleDetails.name) this.payload.name = this.ruleDetails.name;
          if (this.remarks) this.payload.remarks = this.remarks;
          if (this.type === 'add') {
            this.payload.configDesc = this.remarks;
            this.payload.name = this.ruleName;
          }
          if (this.minorMajor) {
            this.payload.minorMajor = this.minorMajor;
          }
          this.$http
            .post('/api/SpreadingConfigMods', this.payload)
            .then((response) => {
              this.snackbar = true;
              this.snackcolor = 'green';
              this.message = 'Config Updated Successfully';
              setTimeout((e) => {
                this.$router.push({
                  name: 'SpreadingDashboard',
                  params: {
                    type: 'all',
                  },
                });
              }, 2000);
            })
            .catch((error) => {
              console.error(error.response.data.error.message);
              this.snackcolor = 'red';
              this.snackbar = true;
              this.message = error.response.data.error.message;
            });
        }
      } catch (error) {
        this.snackbar = true;
        this.snackcolor = 'red';
        this.message = 'Upload Failed';
        console.error('There was an error during uploading rule!', error);
      }
    },
    downloadRule(id, model, version) {
      RuleFunctions.downloadRule(id, model, version);
    },
    approveRule(rule) {
      let api = '/api/SpreadingConfigMods/approve';
      let approvePayload = {
        ...rule,
      };
      delete approvePayload.weight;
      this.$http
        .post(api, approvePayload)
        .then((response) => {
          this.snackbar = true;
          this.snackcolor = 'green';
          this.message = 'Rule Change Approved';
          setTimeout((e) => {
            this.$router.push({
              name: 'SpreadingDashboard',
              params: {
                type: 'approve',
              },
            });
          }, 2000);
        })
        .catch((error) => {
          console.error(error.response.data.error.message);
          this.snackbar = true;
          this.snackcolor = 'red';
          this.alerttype = 'error';
          this.message = error.response.data.error.message;
        });
    },
    rejectRule(rule) {
      let api = '/api/SpreadingConfigMods/reject';
      let rejectPayload = {};
      rejectPayload.id = rule.id;
      rejectPayload.name = rule.name;
      this.$http
        .post(api, rejectPayload)
        .then((response) => {
          this.snackbar = true;
          this.snackcolor = 'green';
          this.message = 'Rule Change Rejected';
          setTimeout((e) => {
            this.$router.push({
              name: 'SpreadingDashboard',
              params: {
                type: 'approve',
              },
            });
          }, 2000);
        })
        .catch((error) => {
          console.error(error.response.data.error.message);
          this.snackbar = true;
          this.snackcolor = 'red';
          this.message = error.response.data.error.message;
        });
    },
    getDecisionDetails(rule) {
      return RuleFunctions.getDecisionRules(rule);
    },
    getCurrentDecisionDetails() {
      return RuleFunctions.getDecisionRules(this.currentRule);
    },
  },
  created() {
    this.minorMajor =
      this.type === 'add'
        ? 'Major'
        : this.type === 'approve'
        ? this.ruleDetails.minorMajor
        : 'Minor';
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-btn {
  color: white;
}

.v-expansion-panel-header {
  min-height: unset;
  padding: unset;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: unset;
}

.v-card__title {
  word-break: unset;
  line-height: unset;
  font-size: 1rem;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 4px 12px;
}

.v-expansion-panel-content__wrap {
  font-size: 0.875rem !important;
}
</style>
