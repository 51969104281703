<template>
  <div>
    <div class="container-fluid overflow-auto">
      <div class="row">
        <Sidebar />
        <div class="col-md-10 offset-md-2 pr-10 mt-5">
          <div class="pl-5 mt-5 pb-10">
            <div class="mt-4 mb-4">
              <strong>Document Creation Logs</strong>
            </div>
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  class="col-4 p-0"
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="logs"
                :search="search"
                :items-per-page="10"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.srlno }}</td>
                    <td>{{ row.item.docName }}</td>
                    <td>
                      {{
                        row.item.entityData && row.item.entityData.entityType
                          ? row.item.entityData.entityType
                          : ''
                      }}
                    </td>
                    <td>
                      {{
                        row.item.entityData && row.item.entityData.entityId
                          ? row.item.entityData.entityId
                          : ''
                      }}
                    </td>
                    <td>
                      {{ new Date(row.item._createdOn).toLocaleString() }}
                    </td>
                    <td>{{ row.item.isError }}</td>
                    <td>
                      <div class="col d-flex justify-content-end">
                        <v-icon
                          v-if="!row.item.isError"
                          class="fas p-2"
                          @click="downloadResponse(row.item)"
                          title="Download Response"
                          >mdi-download-circle</v-icon
                        >
                        <v-icon
                          class="fas p-2"
                          @click="viewLogDetails(row.item)"
                          title="View"
                          >mdi-eye</v-icon
                        >
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import { CommonFunctions } from '../common/common-functions.js';

export default {
  name: 'ViewDocLogs',
  components: {
    Sidebar,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          value: 'srlno',
          text: '#',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: 'docName',
          text: 'Doc Name',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: 'entityData?.entityType',
          text: 'Entity Type',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: 'entityData?.entityId',
          text: 'Entity Id',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: '_createdOn',
          text: 'Created On',
          class: 'text-white primary tableHeaderClass',
        },
        {
          value: 'isError',
          text: 'is Error?',
          class: 'text-white primary tableHeaderClass',
        },
        {
          text: '',
          class: 'text-white primary tableHeaderClass',
        },
      ],
      logs: [],
    };
  },
  methods: {
    fetchLogs() {
      let url = `/api/DocgenMonitors?filter={ "order": "_createdOn DESC", "fields": {"requestData": false, "responseData": false}, "limit": 100 }`;
      let datares = fetch(url)
        .then((response) => response.json())
        .then((datares) => {
          this.logs = [];
          if (datares.length) {
            let count = 1;
            datares.map((m) => {
              m.srlno = count;
              count++;
              m.createdOnFormatted = CommonFunctions.convertTime(
                m._createdOn,
                false
              );
              m.modifiedOnFormatted = CommonFunctions.convertTime(
                m._modifiedOn,
                false
              );
              return m;
            });
            this.logs = datares;
          }
        })
        .catch((error) => {
          console.error(
            'There has been a problem with your fetch operation:',
            error
          );
        });
    },
    viewLogDetails(log) {
      if (log) {
        this.$router.push({
          name: 'ViewLog',
          params: {
            id: log.id,
          },
        });
      }
    },
    downloadResponse(log) {
      const res = this.$http
        .get(`/api/DocgenMonitors/${log.id}`)
        .then((res) => {
          if (res.data.responseData) {
            const blobData = new Blob([atob(res.data.responseData[0])], {
              type: 'html',
            });
            const file = window.URL.createObjectURL(blobData);

            let dwnElement = document.createElement('a');
            dwnElement.setAttribute('download', `${res.data.docName}.html`);
            dwnElement.setAttribute('href', file);
            dwnElement.click();
          }
        })
        .catch((error) => {
          console.error(error.response.data.error.message);
          this.snackbar = true;
          this.snackcolor = 'red';
          this.message = error.response.data.error.message;
        });
    },
  },
  async created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: 'Login',
      });
    }
    await this.fetchLogs();
  },
  watch: {
    $route(to, from) {
      this.fetchLogs(to.params.type);
    },
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-btn {
  color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
