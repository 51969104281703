<template>
  <div class="pl-5 mt-5 pb-10">
    <div class="mt-4 mb-4">
      <strong>
        {{ addUpdateUserTitle }}
      </strong>
    </div>
    <v-card class="p-4 m-4">
      <div class="d-flex justify-content-end">
        <v-btn class="btn btn-primary ms-auto p-1" @click="addUpdateUser()">
          {{ addUpdateUserTitle }}
        </v-btn>
      </div>

      <br />
      <br />
      <div class="row ml-2">
        <div class="col-6">
          <!-- <v-text-field
          v-model="username"
          label="User Name"
          class="required"
          required

          clearable
          v-on:blur="getExistingUserDetails()"
        ></v-text-field> -->
          <label for="username" class="pb-1 mandatory">User Name</label>
          <v-combobox :items="userIds" v-model="username" outlined dense solo clearable class="required" v-on:change="getExistingUserDetails"></v-combobox>
          <label for="password" class="pb-1 mandatory">Password</label>
          <v-text-field v-model="password" outlined dense solo clearable type="password"></v-text-field>

          <div class="text-secondary py-0">
            <div><b><small>Password must contain</small></b></div>
            <div class="row py-0">
              <div class="col-6 pt-1 pb-0" v-bind:class="[validation_min ? 'success-text' : 'text-danger']" v-if="check_pw_requirements('min_length')">
                <small><i class="fas fa-check me-1"></i>Min {{pw_requirements.min_length}} characters</small>
              </div>
              <div class="col-6 pt-1 pb-0" v-bind:class="[validation_max ? 'success-text' : 'text-danger']" v-if="check_pw_requirements('max_length')">
                <small><i class="fas fa-check me-1"></i>Max {{pw_requirements.max_length}} characters</small>
              </div>
              <div class="col-6 py-0" v-bind:class="[validation_number ? 'success-text' : 'text-danger']" v-if="check_pw_requirements('digits')">
                <small><i class="fas fa-check me-1"></i>{{pw_requirements.digits}} number(s)</small>
              </div>
              <div class="col-6 py-0" v-bind:class="[validation_upper ? 'success-text' : 'text-danger']" v-if="check_pw_requirements('upper_case_characters')">
                <small><i class="fas fa-check me-1"></i>{{pw_requirements.upper_case_characters}} uppercase letter(s)</small>
              </div>
              <div class="col-6 py-0" v-bind:class="[validation_special ? 'success-text' : 'text-danger']" v-if="check_pw_requirements('special_characters')">
                <small><i class="fas fa-check me-1"></i>{{pw_requirements.special_characters}} special character(s)</small>
              </div>
              <div class="col-6 py-0" v-bind:class="[validation_lower ? 'success-text' : 'text-danger']" v-if="check_pw_requirements('lower_case_characters')">
                <small><i class="fas fa-check me-1"></i>{{pw_requirements.lower_case_characters}} lowercase letter(s)</small>
              </div>
            </div>

          </div>

        </div>

        <div class="col-6">
          <label for="userFullName" class="pb-1 mandatory">User Full Name</label>
          <v-text-field v-model="userFullName" outlined dense solo clearable></v-text-field>
          <label for="email" class="pb-1 mandatory">Email</label>
          <v-text-field v-model="email" outlined dense solo clearable></v-text-field>
        </div>
        <div class="col-12">
          <strong>
            <label for="userRoles" class="pb-1 mandatory">Roles</label>
          </strong>
          <!-- <v-card flat> -->
          <v-row>
            <template v-for="role in roles">
              <v-checkbox v-model="userRoles" :key="role.id" :label="role.name" :value="role.name" class="col-3"></v-checkbox>
            </template>
          </v-row>
          <!-- </v-card> -->
        </div>
        <div class="col-12">
           <strong>
            <label for="userTenants" class="pb-1 mandatory">Tenants</label>
          </strong>
          <!-- <v-card flat> -->
          <v-row>
            <template v-for="tenant in tenants">
              <v-checkbox v-model="userTenants" :key="tenant.tenantId" :label="tenant.tenantName" :value="tenant.tenantId" class="col-3"></v-checkbox>
            </template>
          </v-row>
          <!-- </v-card> -->
        </div>
      </div>
      <div>
        <v-snackbar class="m-5" v-model="snackbar" :timeout="timeout" :color="snackcolor">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-card>
  </div>

  <!-- <p class="mt-3 justify-content-end">Current Page: {{ currentPage }}</p> -->
</template>

<script>
export default {
  name: "RegisterUser",
  props: {},
  data() {
    return {
      username: "",
      password: "",
      userFullName: "",
      email: "",
      tenants: [],
      roles: [],
      userRoles: [],
      userTenants: [],
      existingUser: false,
      addUpdateUserTitle: "Add User",
      message: "",
      existingUserId: "",
      userIds: [],
      updateUserObj: {},
      timeout: 3000,
      snackbar: false,
      snackcolor: "red",
      pw_requirements: {
        "words": 1,
        "digits": 1,
        "letters": 1,
        "max_length": 25,
        "min_length": 6,
        "expire_days": 0,
        "previous_passwords": 3,
        "special_characters": 1,
        "lower_case_characters": 1,
        "upper_case_characters": 1
      },
    };
  },
  methods: {
    async addUpdateUser() {
      if (!this.passwordIsValid) {
        this.snackbar = true;
        this.message = 'Please check and correct the password field as per password requirements.';
        this.snackcolor = 'red';
        return;
      }
      let res;
      let userObj = {};
      userObj.username = this.username;
      if (this.password) userObj.password = this.password;
      userObj.userFullName = this.userFullName;
      userObj.email = this.email;
      if (!this.userTenants.length) this.userTenants = ["default"];
      if (!this.userRoles.length) this.userRoles = ["user"];
      userObj.tenants = this.userTenants;
      userObj.primaryTenant = this.userTenants[0];
      userObj.roles = this.userRoles;
      userObj.emailVerified = true;
      let beError;

      try {
        if (this.existingUser) {
          this.updateUserObj.userFullName = this.userFullName;
          this.updateUserObj.email = this.email;
          this.updateUserObj.tenants = this.userTenants;
          this.updateUserObj.primaryTenant = this.userTenants[0];
          this.updateUserObj.roles = this.userRoles;
          if (this.password) this.updateUserObj.password = this.password;

          let api = `/api/AppUsers/modifyUser`;
          res = await this.$http.post(api, this.updateUserObj);
        } else {
          let api = `/api/AppUsers/register`;
          res = await this.$http.post(api, userObj);
        }
      } catch (e) {
        beError = e.response.data.error.message;
      }
      this.snackbar = true;
      if (res && res.status == 200) {
        this.message = this.existingUser ?
          "User Updated Successfully" :
          "User Registered Successfully";
        this.snackcolor = 'green';
      } else {
        this.message =
          (this.existingUser ?
            "User Update Failed" :
            "User Registeration Failed") + ` : ${beError}`;
        this.snackcolor = 'red';
      }
    },
    async getExistingUserDetails() {
      if (this.username) {
        try {
          let res = await this.$http.get(
            `/api/AppUsers?filter[where][username]=${this.username}`
          );
          if (res.data && res.data.length) {
            this.existingUser = true;
            this.existingUserId = res.data[0].id;
            this.userTenants = res.data[0].tenants;
            this.userRoles = res.data[0].roles;
            this.userFullName = res.data[0].userFullName;
            this.email = res.data[0].email;
            this.updateUserObj = res.data[0];
            this.addUpdateUserTitle = "Update User";
          } else {
            this.addUpdateUserTitle = "Add User";
          }
        } catch (e) {
          this.snackcolor = 'red';
          this.snackbar = true;
          this.message = e.response.data.error.message
          console.log(e.response.data.error.message);
        }
      }
    },
    check_pw_requirements(key) {
      return key in this.pw_requirements;
    },
  },
  async created() {
    let res = await this.$http.get("/api/Tenants");
    this.tenants = res.data;
    res = await this.$http.get("/api/Roles");
    this.roles = res.data;
    res = await this.$http.get(
      "/api/AppUsers?filter[fields][username]=1&filter[order][0]=username%20ASC"
    );
    this.userIds = res.data.map((u) => u.username);
  },
  computed: {
    validation_min() {
      if (this.check_pw_requirements('min_length') && this.password) {
        return this.password.length >= this.pw_requirements.min_length;
      }
      return false;
    },
    validation_max() {
      if (this.check_pw_requirements('max_length') && this.password) {
        return this.password.length > 0 && this.password.length <= this.pw_requirements.max_length;
      }
      return false;
    },
    validation_number() {
      if (this.check_pw_requirements('digits') && this.password) {
        return this.password.replace(/[^0-9]/g, '').length >= this.pw_requirements.digits;
      }
      return false;
    },
    validation_upper() {
      if (this.check_pw_requirements('upper_case_characters') && this.password) {
        return this.password.replace(/[^A-Z]/g, '').length >= this.pw_requirements.upper_case_characters;
      }
      return false;
    },
    validation_special() {
      if (this.check_pw_requirements('special_characters') && this.password) {
        return this.password.replace(/[^ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g, '').length >= this.pw_requirements.special_characters;
      }
      return false;
    },
    validation_lower() {
      if (this.check_pw_requirements('lower_case_characters') && this.password) {
        return this.password.replace(/[^a-z]/g, '').length >= this.pw_requirements.lower_case_characters;
      }
      return false;
    },
    validation_match() {
      return true;
    },
    passwordIsValid() {
      return (this.validation_min
        && this.validation_max
        && this.validation_lower
        && this.validation_number
        && this.validation_special
        && this.validation_upper
        && this.validation_match);
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-btn {
  color: white;
}
</style>
