<template>
  <div class="row">
    <div class="col-12 pt-0">
      <div class="rule-definition-panel">
        <div class="rules-table-panel">
          <div class="rules-table-header rules-table-row">
            <div class="rules-table-cell mx-w-15">Hit Policy</div>
            <div class="rules-table-cell text-left">
              <span :class="getValueClass('hitPolicy', null, null)">{{decisionRules.hitPolicy}}</span>
            </div>
          </div>

          <template v-for="(row1, i) in decisionRules.context">
            <div :key="i" class="rules-table-header rules-table-row text-primary">
              <div class="rules-table-cell mx-w-25">
                <span :class="getValueClass('context', row1, 'inp')" :title="row1.inp">{{row1.inp}}</span>
              </div>
              <div class="rules-table-cell text-left">
                <span :class="getValueClass('context', row1, 'op')" :title="row1.op">{{row1.op}}</span>
              </div>
            </div>
          </template>
          <v-data-table :headers="ruleHeaders" :items="ruleDataItems" item-key="index" hide-default-footer disable-pagination class="rule-data-table">
            <template v-for="(headerItem, idx) in ruleHeaders" v-slot:[`item.${headerItem.value}`]="{ item }">
              <!-- {{item[headerItem.value]}} -->
              <span :key="headerItem.value + idx">
                <span :class="getCellType(headerItem.value, item)" :title="item[headerItem.value]">{{item[headerItem.value]}}</span>
              </span>
            </template>
          </v-data-table>
          <!-- <div class="rules-table-header rules-table-row">
            <template v-for="(column, i) in decisionRules.tableColumns">
              <div :key="i" :class="['rules-table-cell', column.type + '-type']" :title="column.key.replace('_col_type_inp', '').replace('_col_type_out', '')">
                <span :class="getValueClass('tableColumns',column,column.key.replace('_col_type_inp', '').replace('_col_type_out', ''))">{{column.key.replace("_col_type_inp", "").replace("_col_type_out", "")}}</span>
              </div>
            </template>
          </div>
          <div class="rules-table-body">
            <template v-for="(row, i) in decisionRules.table">
              <div :key="i" class="rules-table-row">
                <template v-for="(column, j) in decisionRules.tableColumns">
                  <div :key="j" :class="['rules-table-cell', column.type + '-type']" :title="row[column.key]">
                    <span :class="getValueClass('table', row, column.key)">{{
                      row[column.key]
                    }}</span>
                  </div>
                </template>
              </div>
            </template>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JsonDiffer } from "json-difference";
export default {
  name: "RuleTable",
  props: {
    decisionRules: Object,
    called_from: String,
    currentRule: Object,
  },
  data() {
    return {
      hitPolicyChanged: false,
    };
  },
  methods: {
    getValueClass(type, row, columnKey) {
      if (row && row.modified_fields && row.modified_fields.length) {
        if (row.modified_fields.includes(columnKey) || row.modified_fields.includes("new_row"))
          return "text-danger fw-bold";
      }
      if (type === "hitPolicy" && this.hitPolicyChanged)
        return "text-danger fw-bold";
      return "";
    },
    getCellType(key, item) {
      if (item.modified_fields && item.modified_fields.length) {
        if (item.modified_fields.includes(key + '_col_type_inp') || item.modified_fields.includes(key + '_col_type_out') || item.modified_fields.includes("new_row"))
          return "text-danger";
      }
      if (this.decisionRules.tableColumns) {
        if (this.decisionRules.tableColumns.map(c => c.key).includes(key + '_col_type_inp')) return 'text-condition';
      }
      return 'text-success'
    }
  },

  created() { },

  watch: {
    decisionRules() {
      if (this.called_from === "new_rule" && Object.keys(this.currentRule).length) {
        const jsondifference = new JsonDiffer();
        let ruleDifference = jsondifference.getDiff(
          this.currentRule,
          this.decisionRules
        );
        //console.log(ruleDifference);
        if (!Array.isArray(ruleDifference.edited))
          ruleDifference.edited = [ruleDifference.edited];
        if (ruleDifference.edited && ruleDifference.edited.length) {
          for (let e = 0; e < ruleDifference.edited.length; e++) {
            let changedKey = Object.keys(ruleDifference.edited[e])[0];
            //console.log('changedKey:', changedKey);
            if (changedKey.startsWith("table/")) {
              let idx = changedKey.split("/")[1];
              let field = changedKey.split("/")[2];
              if (!this.decisionRules.table[idx]["modified_fields"])
                this.decisionRules.table[idx]["modified_fields"] = [];
              if (
                !this.decisionRules.table[idx]["modified_fields"].includes(
                  field
                )
              )
                this.decisionRules.table[idx]["modified_fields"].push(field);
            }
            if (changedKey.startsWith("context/")) {
              let idx = changedKey.split("/")[1];
              let field = changedKey.split("/")[2];
              if (!this.decisionRules.context[idx]["modified_fields"])
                this.decisionRules.context[idx]["modified_fields"] = [];
              this.decisionRules.context[idx]["modified_fields"].push(field);
            }
            if (changedKey === "hitPolicy") {
              this.hitPolicyChanged = true;
            }
          }
        }
        for (const [key, value] of Object.entries(ruleDifference.new)) {
          let changedKey = key;
          if (changedKey.startsWith("table/")) {
            let idx = changedKey.split("/")[1];
            let field = changedKey.split("/")[2];
            if (!this.decisionRules.table[idx]["modified_fields"])
              this.decisionRules.table[idx]["modified_fields"] = [];
            if (
              !this.decisionRules.table[idx]["modified_fields"].includes(field)
            )
              this.decisionRules.table[idx]["modified_fields"].push(field);
            else if (
              !this.decisionRules.table[idx]["modified_fields"].includes(
                "new_row"
              )
            )
              this.decisionRules.table[idx]["modified_fields"].push("new_row");
          }
          if (changedKey.startsWith("context/")) {
            let idx = changedKey.split("/")[1];
            if (!this.decisionRules.context[idx]["modified_fields"])
              this.decisionRules.context[idx]["modified_fields"] = [];
            if (
              !this.decisionRules.context[idx]["modified_fields"].includes(
                "new_row"
              )
            )
              this.decisionRules.context[idx]["modified_fields"].push(
                "new_row"
              );
          }
        }
        //console.log(this.decisionRules);;
      }
    },
  },
  computed: {
    ruleHeaders() {
      if (this.decisionRules.tableColumns) {
        const headers = this.decisionRules.tableColumns;
        //const headers = this.decisionRules.tableColumns.map(c => c.key.replace("_col_type_inp", "").replace("_col_type_out", ""));
        const headersData = []
        headers.forEach(h => {
          const header_class = h.type === 'action' ? 'text-success fw-bold' : 'text-condition fw-bold';
          const header_text = h.key.replace("_col_type_inp", "").replace("_col_type_out", "");
          headersData.push({
            text: header_text,
            //align: 'start',
            sortable: false,
            //name: h,
            value: header_text,
            type: 'string',
            class: header_class,
            width: (header_text === 'actions') ? '500px' : ''
          })
        })
        return headersData;
      }
      return [];
    },
    ruleDataItems() {
      if (this.decisionRules.table) {
        const table = this.decisionRules.table;
        //const headers = this.decisionRules.tableColumns.map(c => c.key.replace("_col_type_inp", "").replace("_col_type_out", ""));
        const tableData = []
        table.forEach(t => {
          const data = {}
          for (const [key, value] of Object.entries(t)) {
            const colType = key.includes('_col_type_inp') ? 'input' : 'output';
            const col = key.replace("_col_type_inp", "").replace("_col_type_out", "");
            data[col] = value;
            data[colType] = colType;
          }
          tableData.push(data);
        })

        return tableData;
      }

      return [];
    }
  }
};
</script>

<style  lang="scss" scoped>
.rule-output-panel {
  padding: 8px;
  box-sizing: border-box;
}

.rules-table-row {
  height: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

.rules-table-cell {
  width: 20%;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  font-size: 12px;
}

.rules-table-cell.condition-type {
  color: #4703b3;
  font-style: italic;
}

.rules-table-cell.action-type {
  color: green;
}

.rules-table-cell.modified-type {
  color: red;
}

.rules-table-header {
  font-weight: bold;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.rules-table-body {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.rules-table-panel {
  margin-top: 16px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border: 2px solid;
}

.context-row {
  border: 1px solid rgba(0, 0, 0, 0.5);
  height: 32px;
}

.context-name {
  font-weight: bold;
}

.context-defn {
  font-style: italic;
}

.panel-heading {
  padding: 8px 0px;
  display: block;
}

.mx-w-25{
  max-width: 25%;
}
</style>
