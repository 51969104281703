<template>
  <footer id="f" class="navbar d-flex justify-content-center">
    <div class="text-center text-light">
      Copyright &copy; 2022:
      <a class="text-reset fw-bold p-1 mb-2" href="https://thesummitgrp.com/"
        >thesummitgrp.com</a
      >
      {{ codeNumber }}
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    footerText: String,
  },
  data() {
    return {
      codeNumber: '',
    };
  },
  async created() {
    this.codeNumber = '00';
    //this.codeNumber = process.env.VUE_APP_CODEBUILD_BUILD_NUMBER;
    //console.log("process", process.env);
    let url = '/healthcheck';
    try {
      const res = await this.$http.get(url, {});
      if (res.status === 200) {
        this.codeNumber = res.data.buildVersion;
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.message
      )
        console.error(e.response.data.error.message);
    }
  },
};
</script>

<style>
#f {
  background-color: #002e6d;
  z-index: 5;
}
</style>
