<template>
  <v-card class="elevation-1 m-3">
    <div class="flex-column">
      <div class="col-md-10 offset-md-2 pr-10 mt-5 p-4">
        <div class="row">
          <div class="col-4">
            <strong>{{ rule.name }}</strong
            >&nbsp;&nbsp;&nbsp;v{{ rule.custVersion }}.{{
              rule.minorVersion || 0
            }}
            <div class="text-caption">
              {{ rule.configDesc }}
            </div>
          </div>
        </div>
        <div class="overflow-x-scroll">
          <div class="container-fluid">
            <div class="row">
              <Sidebar />
            </div>
          </div>
        </div>
        <br />
        <label for="configData" class="pb-1 mandatory">Configuration</label>
        <custom-vue-json-editor
          v-model="rule.configData"
          :mode="'view'"
          :modes="['view']"
          :expandedOnStart="true"
        ></custom-vue-json-editor>
      </div>
    </div>
    <div>
      <v-snackbar
        class="m-5"
        v-model="snackbar"
        :timeout="timeout"
        :color="snackcolor"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-card>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import { RuleFunctions } from '../common/rule-functions';
import CustomVueJsonEditor from '../components/CustomVueJsonEditor.vue';
export default {
  name: 'ViewConfig',
  components: {
    Sidebar,
    CustomVueJsonEditor,
  },
  props: {
    id: String,
    type: String,
  },
  data() {
    return {
      rule: {},
      decisionRules: {},
      ruleInput: '{}',
      ruleOutput: '{}',
      timeout: 3000,
      snackbar: false,
      snackcolor: 'red',
      message: '',
    };
  },
  methods: {},

  async created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: 'Login',
      });
    }
    let model = 'SpreadingConfigs';
    if (this.type === 'approve') model = 'SpreadingConfigMods';
    const res = this.$http
      .get(`/api/${model}/${this.id}`)
      .then((res) => {
        //this.decisionRules = rule.decisionRules ? JSON.parse(rule.decisionRules) : "";
        this.decisionRules = {};
        this.rule = res.data;
        let self = this;
        //let ruleInfo = JSON.parse(this.rule.decisionRules);
        // var ruleInput = {};
        // ruleInfo.inputExpressionList.forEach(function getInputMeta(key) {
        //     ruleInput[key] = "";
        // });
        // this.ruleInput = JSON.stringify(ruleInput, null, 2);
        // //Add input Columns
        // this.decisionRules = RuleFunctions.getDecisionRules(this.rule);
      })
      .catch((error) => {
        console.error(error.response.data.error.message);
        this.snackbar = true;
        this.snackcolor = 'red';
        this.message = error.response.data.error.message;
      });
  },
};
</script>

<style scoped>
#ruleName,
#ruleDesc {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.v-textarea {
  font-size: 0.75rem;
  line-height: 0.8rem;
}

.theme--light.v-btn {
  color: white;
}
</style>
