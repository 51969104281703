<template>
  <div class="form-group">
    <v-menu
      v-model="fromDateMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :name="name"
          :label="label"
          v-model="dateValue"
          :value="dateValue"
          :class="classes"
          :required="required"
          :placeholder="placeholder"
          :title="title"
          :disabled="disabled"
          v-on="on"
          v-show="condition"
        ></v-text-field>
      </template>
      <v-date-picker
        locale="en-in"
        v-model="dateValue"
        :value="dateValue"
        no-title
        color="primary"
        header-color="primary"
        elevation="15"
        @input="emitItemValue"
      ></v-date-picker>
    </v-menu>
  </div>
</template>


  </div>
</template>

<script>
export default {
  name: "DateItem",
  props: {
    name: String,
    label: String,
    value: String,
    classes: String,
    required: Boolean,
    placeholder: String,
    title: String,
    disabled : Boolean,
    condition : Boolean,
  },
  data() {
    return {
      fromDateMenu: false,
      dateValue: this.value,
    };
  },
  methods: {
    emitItemValue(event) {
      this.fromDateMenu = false;
      this.$emit("change", { [this.name]: event });
    },
  },
};
</script>