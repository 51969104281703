export const RuleFunctions = {
    downloadRule: function(id, model, version) {
        let url = "/api/DecisionTables/" + model + "/" + version + "/file";
        let dataresponse = fetch(url)
            .then((response) => response.json())
            .then((dataresponse) => {
                if (dataresponse) {
                    let dwnElement = document.createElement("a");
                    dwnElement.setAttribute("download", dataresponse.documentName);
                    dwnElement.setAttribute("href", dataresponse.documentData);
                    dwnElement.click();
                }
            });
    },
    getDecisionRules: function(rule) {
        let ruleData = {};
        if (rule && rule.decisionRules) {
            let ruleInfo = JSON.parse(rule.decisionRules);
            ruleData.tableColumns = ruleInfo.inputExpressionList.map(
                function setColType(s) {
                    return {
                        key: s + '_col_type_inp',
                        type: "condition",
                    };
                }
            );
            //Add Output Columns
            ruleInfo.outputs.forEach(function setColType(s) {
                ruleData.tableColumns.push({
                    key: s + '_col_type_out',
                    type: "action",
                });
            });

            //Generate Table
            ruleData.hitPolicy = ruleInfo.hitPolicy;
            ruleData.context = [];
            let currContext = rule.contextObject;
            if (currContext) {
                let letdata = Object.entries(currContext);
                let letresult = [];
                for (let i in letdata) letresult.push({ inp: letdata[i][0], op: letdata[i][1] });
                //Object.entries(letdata).map(([key, val]) => { letresult.push({inp: key, op: val}) });
                ruleData.context = letresult;
            }
            ruleData.table = ruleInfo.ruleList.map(function(ruleVal) {
                let ruleObj = {};
                ruleVal.forEach(function mapToObj(val, idx) {
                    ruleObj[ruleData.tableColumns[idx].key] = val;
                });
                return ruleObj;
            });
        }
        return ruleData;
    }
}