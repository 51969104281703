<template>
  <div class="pl-5 mt-5 pb-10">
    <div class="mt-4 mb-4">
      <strong>
        {{ addUpdateTenantTitle }}
      </strong>
    </div>
    <v-card class="p-4 m-4">
      <div class=" d-flex justify-content-end">
        <v-btn class="btn btn-primary ms-auto p-1" @click="addUpdateTenant()">
          {{ addUpdateTenantTitle }}
        </v-btn>
      </div>
      <br />
      <br />
      <div class="row ml-2">
        <div class="col-6">
          <!-- <v-text-field
          v-model="tenantId"
          label="Tenant Id"
          class="required"
          required
          clearable
          v-on:blur="getExistingTenantDetails()"
        ></v-text-field> -->
          <label for="tenantIds" class="pb-1 mandatory">Tenant Id</label>
          <v-combobox :items="tenantIds" v-model="tenantId" class="required" v-on:change="getExistingTenantDetails" outlined dense solo clearable></v-combobox>
          <label for="tenantName" class="pb-1 mandatory">Tenant Name</label>
          <v-text-field v-model="tenantName" outlined dense solo clearable class="required"></v-text-field>
        </div>
      </div>
      <div>
        <v-snackbar class="m-5" v-model="snackbar" :timeout="timeout" :color="snackcolor">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-card>
  </div>

  <!-- <p class="mt-3 justify-content-end">Current Page: {{ currentPage }}</p> -->
</template>

<script>
export default {
  name: "RegisterTenant",
  props: {},
  data() {
    return {
      tenantId: "",
      tenantName: "",
      existingTenant: false,
      addUpdateTenantTitle: "Add Tenant",
      message: "",
      existingTenantId: "",
      existingTenantVersion: "",
      tenants: [],
      tenantIds: [],
      timeout: 3000,
      snackbar: false,
      snackcolor: "red",
    };
  },
  methods: {
    async addUpdateTenant() {
      let res;
      let beError;
      let tenantObj = {};
      tenantObj.tenantId = this.tenantId;
      tenantObj.tenantName = this.tenantName;
      try {
        if (this.existingTenant) {
          tenantObj._version = this.existingTenantVersion;
          let api = `/api/Tenants/${this.existingTenantId}`;
          res = await this.$http.patch(api, tenantObj);
        } else {
          let api = `/api/Tenants/`;
          res = await this.$http.post(api, tenantObj);
        }
      } catch (e) {
        beError = e.response.data.error.message;
      }
      this.snackbar = true;
      if (res && res.status == 200) {
        this.message = this.existingTenant ?
          "Tenant Updated Successfully" :
          "Tenant Registered Successfully";
        this.snackcolor = 'green';
      } else {
        this.message = (this.existingUser ?
          "Tenant Update Failed" :
          "Tenant Registration Failed") + ` : ${beError}`;
        this.snackcolor = 'red';
      }
    },
    async getExistingTenantDetails() {
      this.tenantName = "";
      if (this.tenantId) {
        let tenant = this.tenants.filter((t) => t.tenantId === this.tenantId);
        if (tenant && tenant.length) {
          this.existingTenant = true;
          this.existingTenantId = tenant[0].id;
          this.tenantName = tenant[0].tenantName;
          this.existingTenantVersion = tenant[0]._version;
          this.addUpdateTenantTitle = "Update Tenant";
        } else {
          this.addUpdateTenantTitle = "Add Tenant";
        }
      }
    },
  },
  async created() {
    let res = await this.$http.get("/api/Tenants");
    this.tenants = res.data;
    this.tenantIds = this.tenants.map((x) => x.tenantId);
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-btn {
  color: white;
}
</style>
