<template>
<div>
    <Login @show-alert="showMessage" />
    <div>
       <v-snackbar class="m-5" v-model="snackbar" :timeout="timeout" :color="snackcolor">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
      </div>
</div>
</template>

<script>
import Login from "../components/Login.vue";
// import Alert from "../components/Alert.vue";
export default {
    name: "Home",
    components: {
        Login,
    },
    data() {
        return {
            message: "",
            showAlert: false,
            timeout: 3000,
            snackbar: false,
            snackcolor: "red",
        };
    },
    methods: {
        showMessage(type, message) {
            this.snackbar = true;
            this.snackcolor = type ? "green" : "red";
            this.message = message;
        },
    },
};
</script>

<style scoped>
</style>
