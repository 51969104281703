import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                primary: '#002E6D',
                secondary: '#02908b',
                active: '#000000',
                tertiary: '#a59efa',
                // secondary: '#b0bec5',
                // accent: '#8c9eff',
                // error: '#b71c1c',
            },
        },
    },
}

export default new Vuetify(opts)