<template>
  <div class="form-group">
    <v-radio-group      
      v-model="selectedValue"
      :name="name"
      :label="label"
      :mandatory="mandatory"
      :required="required"
      :placeholder="placeholder"
      :title="title"
      :disabled="disabled"
      v-on:change="emitItemValue"
      v-show="condition"
    >
      <v-radio
        v-for="r in keyValues"
        :key="r"
        :label="r"
        :value="r"
        :name="name + r"        
        :disabled="disabled"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: "RadioItem",
  props: {
    name: String,
    label: String,
    value: String,
    classes: String,
    required: Boolean,
    placeholder: String,
    title: String,
    disabled : Boolean,
    condition : Boolean,
    keyValues : Array,
  },
  data() {
    return {
      selectedValue: this.value,
      mandatory: false,
    };
  },
  methods: {
    emitItemValue(event) {
      this.$emit("change", { [this.name]: event });
    },
  },
};
</script>