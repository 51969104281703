<template>
<div>
    <div class="overflow-hidden">
        <div class="container-fluid">
            <div class="row">
                <Sidebar />
                <div class="col-md-10 offset-md-2 pr-10 overflow-hidden">
                    <div class="row">
                        <div class="col-10">
                            <RegisterUser />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import RegisterUser from "../components/RegisterUser.vue";
export default {
    name: "AddEditUser",
    components: {
        Sidebar,
        RegisterUser,
    },
    data() {
        return {

        };
    },
    methods: {},
    created() {
        if (sessionStorage.length == 0) {
            this.$router.push({
                name: "Login",
            });
        }
    }
};
</script>

<style scoped>
</style>
