<template>
  <!-- <div :class="[compareClass,'ms-auto','pb-10 mb-10']"> -->
  <div >
    <v-container class="p-0">
      <v-row no-gutters>
        <v-btn class="btn btn-primary ms-auto" color="primary" @click="compareRuleData(rule)">
          Test and Compare
        </v-btn>
      </v-row>
    </v-container>
    <div class="justify-content-center">
      <template v-if="showCompareResult">
        <v-container class="p-0">
          <v-row no-gutters>
            <v-col class="col-8 text-primary fw-bold">
              <v-card class="pa-2" outlined tile>
                Total Records
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2 text-primary fw-bold" outlined tile>
                {{compareResult.totalRecords || 0}}
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-8 text-primary fw-bold">
              <v-card class="pa-2" outlined tile>
                Matched
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2 text-success fw-bold" outlined tile>
                {{compareResult.matchedRecords || 0}}
              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-8 text-primary fw-bold">
              <v-card class="pa-2" outlined tile>
                Unmatched
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2 text-danger fw-bold" outlined tile>
                {{compareResult.unmatchedRecords || 0}}
              </v-card>
            </v-col>
          </v-row>
          <v-divider v-show="compareResult.unmatchedRecords > 0"></v-divider>
          <v-row no-gutters>
            <v-btn class="btn btn-primary ms-auto" color="primary" @click="downloadComparisonResult()" v-show="compareResult.unmatchedRecords > 0">
              Download Unmatched Records
            </v-btn>
          </v-row>
        </v-container>
      </template>
    </div>
  </div>
</template>

<script>
import { CommonFunctions } from "../common/common-functions.js";
export default {
  name: "CompareRule",
  props: {
    rule: Object,
    type: String,
    newdata: Object
  },
  components: {},
  data() {
    return {
      showCompareResult: false,
      compareResult: {},
      compareClass: (this.type === 'edit') ? 'col-12' : 'col-4 mt-4'
    };
  },
  methods: {
    compareRuleData(rule) {
      if (this.type === 'edit') {
        if (!this.newdata || !this.newdata.documentData) {
          alert("Upload file before Comparing the rule");
          return;
        }
      }
      let api = "/api/DecisionTableMods/compare";
      this.showCompareResult = true;

      let comparePayload = {};
      comparePayload.id = rule.id;
      comparePayload.name = rule.name;
      comparePayload._version = rule._version;
      comparePayload.decisionRules = rule.decisionRules;
      comparePayload.comparisonType = 'approval';
      if (this.type === 'edit') {
        comparePayload.decisionRules = {};
        comparePayload.documentData = this.newdata.documentData;
        comparePayload.comparisonType = 'edit';
      }
      this.$http
        .post(api, comparePayload)
        .then((response) => {
          console.log(response.data);
          this.compareResult = response.data;
        })
        .catch((error) => {
          //console.error("There was an error during restoring rule!", error);
          console.error(error.response.data.error.message);
        });

    },
    downloadComparisonResult() {
      let dwnElement = document.createElement("a");
      dwnElement.setAttribute("download", `${this.rule.name}_ComparisonResult_${CommonFunctions.getDateString()}.xlsx`);
      dwnElement.setAttribute("href", this.compareResult.excelData);
      dwnElement.click();
    },
  },
  created() {
    if (sessionStorage.length == 0) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>

<style scoped>
</style>
