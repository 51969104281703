<template>
  <div>
    <div class="overflow-hidden">
      <div class="container-fluid">
        <div class="row">
          <Sidebar />
          
          <div class="col-md-12 offset-md-2 pr-10  overflow-hidden">
            <div class="row">
              <div class="col-10">
                <div class="row d-flex my-4 ml-10">
                  <!-- <div class="col-3">
                    <v-combobox
                      :items="bankList"
                      v-model="bank"
                      label="Bank"
                      required
                      class="required"
                    ></v-combobox>
                  </div> -->
                  <div class="col-4">
                    <v-combobox
                      :items="productList"
                      v-model="product"
                      label="Product"
                      required
                      class="required"
                      v-on:change="getFieldMetadata"
                    ></v-combobox>
                  </div>
                  <h6 class="fw-bolder" v-if="showUI">{{ sectionName }}</h6>
                  <DynaUI
                    v-if="showUI"
                    :metadata="metadata"
                    :section="sectionName"
                  />
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div id="message_div" v-show="showMessage">
      <!-- <Alert :type="type" :message="message" /> -->
      <v-alert dense :type="alerttype">
        {{ message }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue";
import DynaUI from "../components/DynaUI.vue";

export default {
  name: "DynamicUI",
  components: {
    Sidebar,
    DynaUI,
  },
  props: {},
  data() {
    return {
      alerttype: "success",
      message: "",
      showMessage: false,
      bankList: ["PENN", "CENTIER"],
      bank: "PENN",
      productList: ["Equipment & Vehicle Loan", "Working Capital LOC"],
      metadata: {},
      product: "",
      sectionName: "Applicant Information",
      showUI: false,
    };
  },
  methods: {
    getFieldMetadata() {
      try {
        this.showUI = false;
        let api = `/api/DecisionTables/exec/DynamicUI`;
        let payload = {};
        payload.Customer = this.bank;
        payload.ProductType = this.product;
        payload.Screen = this.sectionName;
        this.$http
          .post(api, payload)
          .then((response) => {
            this.showUI = true;
            this.metadata.fields = Array.isArray(response.data)
              ? response.data
              : [response.data[0]];
            //this.metadata.fields[0].disabled = true;
            //console.log(response.data)
            this.metadata.fields.sort(
              (a, b) => a.displayOrder - b.displayOrder
            );
            // this.metadata.fields.forEach((element) => {
            //   element.condition = true;
            // });
            //this.metadata.fields[1].showCondition = "@i.spec_equipment_flg == 'No'";
            let api = `/api/DecisionTables/exec/Documents`;
            let payload = {};
            payload.Customer = this.bank;
            payload.ProductType = this.product;
            this.$http.post(api, payload).then((response) => {
              this.showUI = true;
              this.metadata.documents = [];
              if (response.data && response.data.length)
                this.metadata.documents = Array.isArray(response.data)
                  ? response.data
                  : [response.data[0]];
            });
          })
          .catch((error) => {
            console.error(error.response.data.error.message);
            this.showMessage = true;
            this.alerttype = "error";
            this.message = error.response.data.error.message;
          });
      } catch (error) {
        console.error(error.response.data.error.message);
        this.showMessage = true;
        this.alerttype = "error";
        this.message = error.response.data.error.message;
      }
    },
  },
};
</script>