<template>
  <div class="form-group">    
    <v-combobox
      :items="keyValues"
      :v-model="value"
      :value="value"
      :name="name"
      :label="label"
      :required="required"
      :placeholder="placeholder"
      :title="title"
      :class="classes"
      :disabled="disabled"
      @input="emitItemValue"
      v-show="condition"
    ></v-combobox>
  </div>
</template>

<script>
export default {
  name: "ComboItem",
  props: {
    name: String,
    label: String,
    value: String,
    keyValues: Array,
    classes : String,
    required : Boolean,
    placeholder : String,
    title : String,
    disabled : Boolean,
    condition : Boolean,
  },
  methods: {
    emitItemValue(event) {
      this.$emit("change", { [this.name]: event });
    },
  },
};
</script>