<template>
  <div class="pl-5 mt-5 pb-10">
    <div class="mt-4 mb-4">
      <strong>{{ dashboardType }}</strong>
    </div>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="col-4 p-0"></v-text-field>
        <v-btn depressed class="btn btn-primary ms-auto" @click="addNewRule" v-if="dashboardType != 'Pending Approval'">
          Add New Rule
        </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="rules" :search="search" :items-per-page="5" :custom-sort="customSort">
        <!--<template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="h in headers" :class="h.class" :key="h.key">
              <span>{{h.text}}</span>
            </th>
          </tr>
        </thead>
        </template>-->
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.srlno }}</td>
            <td>{{ row.item.name }}</td>
            <td>{{ row.item.ruleDesc }}</td>
            <td>{{ row.item.level }}</td>
            <td>v{{ row.item.custVersion }}.{{ row.item.minorVersion || 0 }}</td>
            <td>{{ row.item._modifiedBy }}</td>
            <td>{{ row.item.createdOnFormatted }}</td>
            <td>{{ row.item.modifiedOnFormatted }}</td>
            <td>
              <div class="col d-flex justify-content-end">
                <v-icon class="fas p-2" @click="$emit('edit-rule', row.item)" title="Edit">mdi-pencil</v-icon>
                <!-- <v-icon class="fas p-2" @click="$emit('approve-rule', row.item)"
                  title="Edit"
                  v-if="dashboardType == 'Pending Approval'"
                  >mdi-check</v-icon
                > -->
                <v-icon class="fas p-2" @click="
                    $emit('download-rule', row.item.id, row.item._version)
                  " title="Download">
                  mdi-download-circle</v-icon>
                <v-icon class="fas p-2" @click="$emit('delete-rule', row.item.id, row.item._version)" title="Delete" hidden>mdi-delete
                </v-icon>
                <v-icon class="fas p-2" @click="$emit('view-rule', row.item)" title="View">mdi-eye</v-icon>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <!-- <p class="mt-3 justify-content-end">Current Page: {{ currentPage }}</p> -->
  </div>
</template>

<script>
export default {
  name: "Rule",
  props: {
    rules: Array,
    dashboardType: String,
  },
  data() {
    return {
      search: "",
      headers: [
        {
          value: "srlno",
          text: "#",
          class: "text-white primary tableHeaderClass"
        },
        {
          value: "name",
          text: "Rule Name",
          class: "text-white primary tableHeaderClass"
        },
        {
          value: "remarks",
          text: "Description",
          class: "text-white primary tableHeaderClass"
        },
        {
          value: "level",
          text: "Level",
          class: "text-white primary tableHeaderClass"
        },
        {
          value: "custVersion",
          text: "Version",
          class: "text-white primary tableHeaderClass"
        },
        {
          value: "_modifiedBy",
          text: "Last Mod. By",
          class: "text-white primary tableHeaderClass"
        },
        {
          value: "createdOnFormatted",
          text: "Created On",
          class: "text-white primary tableHeaderClass",
        },
        ,
        {
          value: "modifiedOnFormatted",
          text: "Modified On",
          class: "text-white primary tableHeaderClass",
        },
        {
          text: "",
          class: "text-white primary tableHeaderClass"
        },
      ],
    };
  },
  methods: {
    itemRowBackground(item) {
      console.log(item);
      return "";
    },
    addNewRule() {
      this.$router.push({
        name: "AddRule",
        params: {},
      });
    },
    customSort(items, index, desc) {
      if (index.includes('modifiedOnFormatted')) {
        items.sort((a, b) => {
          const modifiedOnFormattedIndex = index.findIndex(a => a === 'modifiedOnFormatted');
          const isDesc = desc[modifiedOnFormattedIndex];
          if (!isDesc) {
            return new Date(a._modifiedOn) - new Date(b._modifiedOn);
          } else {
            return new Date(b._modifiedOn) - new Date(a._modifiedOn);
          }
        });
      }
      if (index.includes('createdOnFormatted')) {
        items.sort((a, b) => {
          const createdOnFormattedIndex = index.findIndex(a => a === 'createdOnFormatted');
          const isDesc = desc[createdOnFormattedIndex];
          if (!isDesc) {
            return new Date(a._createdOn) - new Date(b._createdOn);
          } else {
            return new Date(b._createdOn) - new Date(a._createdOn);
          }
        });
      }
      return items;
    }
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-btn {
  color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>